import { ensureLeadingSlash, removeLeadingSlash } from './urls'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isString = (input: any): input is string => {
  return typeof input === 'string'
}

export const externalUrlRegex = /(https?:\/\/|http?:\/\/|mailto:|tel:|#)/gi

export const handleHref = (value: string) => {
  if (externalUrlRegex.test(value)) {
    return removeLeadingSlash(value.replace(/\s/g, ''))
  }
  return ensureLeadingSlash(value)
}

type Params = { [key: string]: string[] | string }

export const rawSlug = (params: Params) => (Array.isArray(params.slug) ? params?.slug.join('/') : params?.slug || '/')

export const toCamelCase = (str: string) => {
  if (!isString(str) || str === '') return null
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1)
    })
}

export const generateHash = (input: string) => {
  if (!input) return 0
  let hash = 0
  input?.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })

  return Math.abs(hash) // Take the absolute value of the hash
}
