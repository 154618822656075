import SafeLink from '@ignition/library/components/atoms/safe-link'

export const NavigationItem = ({ title, href }) => {
  return href?.length ? (
    <SafeLink id={title} href={href}>
      <span className="text-base font-medium text-mainnav-link gray-900 hover:text-mainnav-link-hover">{title}</span>
    </SafeLink>
  ) : (
    <span className="text-base font-medium text-mainnav-link hover:text-mainnav-link-hover hover:cursor-pointer">
      {title}
    </span>
  )
}
