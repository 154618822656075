import { FC, SyntheticEvent } from 'react'
import clsx from 'clsx'
import NextImage, { ImageProps as NextImageProps } from 'next/image'

export interface ImageProps extends NextImageProps {
  category?: string
  title?: string
  copyright?: string
}

const Image: FC<ImageProps> = ({ className, priority, loading, onLoad, blurDataURL, ...props }) => {
  const lazy = loading !== 'eager' && !priority

  const handleOnLoad = (result: SyntheticEvent<HTMLImageElement, Event>) => {
    if (typeof onLoad === 'function') {
      onLoad(result)
    }
  }

  return (
    <NextImage
      {...props}
      priority={priority}
      loading={loading}
      onLoad={handleOnLoad}
      className={clsx(className, 'transition-opacity duration-300', lazy && !props?.src ? 'opacity-0' : 'opacity-100')}
      blurDataURL={blurDataURL}
      placeholder={blurDataURL ? 'blur' : 'empty'}
    />
  )
}

export default Image
