/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

type LogLevel = 'error' | 'warn' | 'info' | 'debug' | 'trace'
type LogLevelId = 10 | 20 | 30 | 40 | 50
type LogLevelColor = 'red' | 'yellow' | 'blue' | 'magenta' | 'gray'

const LOG_LEVELS: { [key in LogLevel]: { id: LogLevelId; color: LogLevelColor } } = {
  error: {
    id: 50,
    color: 'red',
  },
  warn: {
    id: 40,
    color: 'yellow',
  },
  info: {
    id: 30,
    color: 'blue',
  },
  debug: {
    id: 20,
    color: 'magenta',
  },
  trace: {
    id: 10,
    color: 'gray',
  },
}

class Logger {
  constructor() {}

  public error(...args: any[]) {
    this.handleLog('error', ...args)
  }
  public warn(...args: any[]) {
    this.handleLog('warn', ...args)
  }
  public info(...args: any[]) {
    this.handleLog('info', ...args)
  }

  public debug(...args: any[]) {
    this.handleLog('debug', ...args)
  }
  public trace(...args: any[]) {
    this.handleLog('trace', ...args)
  }

  private handleLog(level: LogLevel, ...args: any[]) {
    if (!this.checkLevel(level)) {
      return
    }

    this.log(level, ...args)
  }

  private async log(level: string, ...args: any[]) {
    switch (level) {
      case 'error': {
        const prefix = await this.colorizeText('[ERROR]', LOG_LEVELS.error.color)
        return console.error(prefix, ...args)
      }
      case 'warn': {
        const prefix = await this.colorizeText('[WARN]', LOG_LEVELS.warn.color)
        return console.warn(prefix, ...args)
      }
      case 'info': {
        const prefix = await this.colorizeText('[INFO]', LOG_LEVELS.info.color)
        return console.info(prefix, ...args)
      }
      case 'debug': {
        const prefix = await this.colorizeText('[DEBUG]', LOG_LEVELS.debug.color)
        return console.log(prefix, ...args)
      }
      case 'trace': {
        const prefix = await this.colorizeText('[TRACE]', LOG_LEVELS.trace.color)
        return console.trace(prefix, ...args)
      }
    }
  }

  private async colorizeText(text: string, color: LogLevelColor) {
    if (typeof window === 'undefined') {
      // server side
      const chalk = (await import('chalk')).default
      return chalk[color](text)
    } else {
      // client side
      return text
    }
  }

  private checkLevel(level: LogLevel): boolean {
    const levelId = LOG_LEVELS[level].id
    const loggerLevel = (process.env.LOG_LEVEL || 'info') as LogLevel
    const loggerLevelId: LogLevelId = LOG_LEVELS[loggerLevel].id ?? 30
    return typeof levelId !== 'undefined' && levelId >= loggerLevelId
  }
}

const logger = new Logger()
export default logger
